import * as React from 'react'
import {graphql} from 'gatsby'
import {useTranslation} from 'gatsby-plugin-react-i18next'
import {ParallaxProvider} from 'react-scroll-parallax'

import {Seo, Layout, HomePage} from '../components'

const Home = () => {
  const {t} = useTranslation()

  return (
    <ParallaxProvider>
      <Layout>
        <Seo title={t('pages.home.seo.title')} description={t('pages.home.seo.description')} />
        <HomePage />
      </Layout>
    </ParallaxProvider>
  )
}

export default Home

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
